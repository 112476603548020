@import "scss/global";

.attendance-filter {
  background: $ui_02;
  border-bottom: 1px solid $ui_04;
  .form {
    width: 100%;
    display: none;
  }
}

.attendance__form {
  padding: $spacing-md;
  &__custom {
    display: flex;
    align-items: flex-end;
    .wfp--btn {
      margin-left: 1rem;
    }
  }
}

.DateRangePicker_picker {
  z-index: 100;
}

.DateInput_input {
  min-width: 9rem;
  max-width: 9rem;
}

.attendance__loading {
  text-align: center;
  justify-content: center;
  height: 70%;
  width: 100%;
}

.deliveries-content.main-content {
  .sync-icon-wrapper {
    margin-left: 0.2em;
    flex-grow: 1;
  }
  .number {
    .wfp--form-item {
      margin: -$spacing-xs;
      input {
        text-align: right;
        border-color: $ui-04;
        border: none;
        position: relative;
        &:focus {
          z-index: 1000;
        }
      }
    }
  }
  .attendance-module {
    width: 100%;
  }
  /* > div {
    grid-template-columns: repeat(1, 1fr);
  }*/
}

//TODO: move to UI-Kit
.attendance-module {
  margin: 1rem 0;
  .wfp--module__content {
    padding: 0;
  }
}

.wfp--module {
  .wfp--module__inner {
    border: 1px solid darken($ui-04, 5);
    box-shadow: none;
  }
}

.meals {
  &__unit {
    max-width: 7rem;
  }
  &__quantity {
    width: 100%;
  }
}

.deliveries-row {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}
