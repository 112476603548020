@import "scss/global";

.input {
  margin: -1px;

  :global {
    .wfp--input-wrapper {
      display: block;
    }
  }
  input[type="number"] {
    min-width: 2rem;

    padding: 0.3em !important;
    &:focus {
      position: relative;
      z-index: 1000;
    }
  }
}
