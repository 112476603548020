@import "scss/global";

.active_container {
}

.active_container__buttons {
  float: left;
  margin-top: $spacing-sm;

  Button {
    margin-right: $spacing-sm;
  }
}
