@import "scss/global";

.section {
  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }
  @include breakpointMax(bp--md--major) {
    border-bottom: 1px solid $ui-04;
  }
}

.wfp--list--details {
  .wfp--list__element__title {
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
  }
}

.wfp--modal-container {
  border-radius: 5px;
}
