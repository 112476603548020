@import "scss/global";

.edit-incident-button {
  margin-left: 1rem;
}

.incident-table {
  border: 1px solid $ui-04 !important;
  /*td {
    border: 1px solid blue !important;
  }*/
}

.reason-list {
  &__item {
    display: inline-block;
    margin-right: 0.3rem;
    &:after {
      content: ";";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
