@import "scss/global";

.wfp--table--extended {
  width: 100%;

  .wfp--table__title {
    font-weight: 600;
  }
  .wfp--table__total {
    font-weight: 600;
  }
  .wfp--table__show-total-value {
    font-weight: 600;
  }
  th,
  td {
    @include breakpointMax(bp--md--major) {
      padding: 0.5em 0.3em;
      font-size: 0.9em;
    }
    border: 1px solid $ui-04;
  }
  .diff {
    td {
      border-top: 2px solid $ui-05;
    }
  }
}

.wfp--table__show-value {
  //text-align: right;
}

.wfp--table__total {
  text-align: right;
}

.wfp--table__title {
  @include breakpoint(bp--md--major) {
    .wfp--table__title__total {
      display: none;
    }
  }
  @include breakpointMax(bp--md--major) {
    font-size: 0.7em;
    vertical-align: middle;
    padding-top: 0.3em !important;
    padding-bottom: 0.3em !important;
    .wfp--table__title__heading {
      //margin-top: -0.7em;
      white-space: nowrap;
    }
    .wfp--table__title__total {
      margin-top: 0.2rem;
      font-weight: 400;
      opacity: 0.6;
      font-size: 0.8em;
    }
  }
}

/*
.wfp--table th {
  text-align: center;
}

.wfp--table--extended {
  width: 100%;
  .wfp--table__title {
    font-weight: 600;
  }
  .wfp--table__total {
    font-weight: 600;
    text-align: center;
  }
  .wfp--table__show-value {
    text-align: center;
  }
  td {
    border: 1px solid $ui-03;
  }
  .diff {
    td {
      border-top: 2px solid $ui-05;
    }
  }
}*/

.wfp--table--no-border {
  tr:first-child td,
  tr:first-child th {
    border-top: 0;
  }
  tr td:first-child,
  tr th:first-child {
    border-left: 0;
  }
  tr:last-child td,
  tr:last-child th {
    border-bottom: 0;
  }
  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }
}

.wfp--table--mobile-list {
  thead {
    display: none;
  }
  tr,
  td {
    display: block;
  }
  tr {
    margin-bottom: 1rem;
  }
  .wfp--table__title {
    background: $ui-03;
  }
}
