@import "scss/global";

.report-state {
  width: 90px;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 8px;
  margin-top: 5px;
  border-radius: 10px;
  text-align: center;
  color: $interactive-01;
  background-color: $ui-02;
}

.report-state-approved {
  color: $support-02;
}
.report-state-validated {
  color: $support-04;
}
.report-state-rejected {
  color: $support-01;
}
