@import "scss/global";

.content {
  @include breakpointMax(bp--sm--major) {
    overflow: hidden;
  }
}

.Toastify__toast-container--top-center {
  margin-top: 3.4375rem;
}

.country-admin-inner-content {
  display: flex;
  align-items: stretch;
  height: calc(100vh - #{$desktop-header-height});
  flex-direction: column;
}

.country-admin-main-content {
  .vendors-table,
  .users-table,
  .schools-levels-table,
  .schools-table,
  .schools-ClassNames-table {
    .wfp--module {
      padding: 0;
    }
  }

  .wfp--wrapper {
    margin: auto !important;
  }

  @include breakpointMax(bp--md--major) {
    overflow: auto;
    padding-top: 70px;
    height: calc(100vh - #{70px});
  }
  @include breakpoint(bp--md--major) {
    max-height: 100%;
  }
}

.country-admin-main-content-center {
  &.school-profiles-table .wfp--module {
    padding: 0;
  }

  .wfp--wrapper {
    margin: auto !important;
  }
  .country-admin-school-profile-form {
    display: flex;
    flex-direction: column;
    .country-admin-submit-button {
      display: flex;
      flex-direction: row-reverse;
      .hidden-btn {
        display: none;
      }
    }
  }
  .country-admin-school-profile-form-row {
    display: flex;
    align-items: center;
    min-height: 2.7rem;
    padding-top: 10px;
    max-width: 45rem;
    flex-wrap: wrap;
    > label {
      max-width: 15rem;
      width: 15rem;
    }

    .wfp--form-item {
      display: flex;
      flex-direction: row;
    }
    .wfp--label {
      min-width: 15rem;
      margin-top: 12px;
    }
    .wfp--text-input {
      max-width: 22rem;
    }
    .react-select-container {
      max-width: 22rem;
    }
    .react-select-container .react-select__control {
      border-radius: 0;
      width: 22rem;
      min-height: 40px;
      background-color: #f9fbfc;
    }
  }

  @include breakpointMax(bp--md--major) {
    overflow: auto;
    padding-top: 70px;
    height: calc(100vh - #{70px});
  }
  @include breakpoint(bp--md--major) {
    max-height: 100%;
  }

  .country-admin-buttons {
    display: flex;
    justify-content: space-between;
  }
}

.inlineLoading {
  display: flex;
  align-self: center;
  .wfp--inline-loading {
    justify-content: center;
  }
}
