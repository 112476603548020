@import "scss/global";

.wrapper {
  //position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  > div {
    max-width: 600px;
    width: 100%;
    @include breakpointMax(bp--md--major) {
      margin: 0 rem(10px);
    }
  }
}

.wrapper--center {
  position: absolute;
  height: 100%;
  align-items: center;
  margin: 0;
  padding-bottom: 3rem;
  > div {
    max-width: 400px;
  }
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html,
body,
#root,
.app-wrapper {
  height: 100%;
  width: 100%;
  //overflow: hidden;
  //position: fixed;
}
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

/*.wfp--number {
  input[type="number"] {
    border-radius: 0;
    box-shadow: none;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}*/

.#{$prefix}--form-long {
  margin-bottom: -1rem;

  > .#{$prefix}--form-item {
    margin-bottom: 1.3rem;
  }
}

.wfp--number--nolabel {
  display: block;
}

.SingleDatePickerInput_calendarIcon {
  z-index: 100;
}

.wfp--main-navigation__trigger {
  a {
    text-decoration: none;
    &.active {
      border-bottom: 2px solid rgba(var(--ui-01), 0.8);
    }
  }
}
.environment-indicator.dev {
  background: #ffbf3b;
}

.environment-indicator.qa {
  background: #8ad220;
}

.environment-indicator {
  position: fixed;
  width: 300px;
  height: 50px;
  background-color: grey;
  bottom: 0px;
  right: 0px;
  text-align: center;
  font-size: 20px;
  pointer-events: none;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  //color: #fff;
  line-height: 50px;
  transform: translateX(95px) rotate(-45deg);
  //opacity: 0.8;
  padding-left: 30px;
  z-index: 100;
}

.wfp--radio-button-group {
  .wfp--radio-button-group-inside {
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacing-xs;
    order: 2;
    width: 100%;
    > * {
      margin-right: $spacing-lg;
    }
    .#{$prefix}--form-item {
      flex: none;
    }
  }
  @include breakpointMax(bp--md--major) {
    .wfp--radio-button-group-inside {
      flex-direction: column;
    }
  }
}

.wfp--number {
  input[type="number"] {
    min-width: 3rem;
  }
}
