@import "scss/global";

.login {
  display: flex;
  height: 100vh;
}

.password {
  margin-left: $spacing-md;
}

.loginForm {
  display: flex;

  flex-direction: column;
  @include breakpointMax(bp--md--major) {
    width: 100%;
    padding: 5vw;
  }

  @include breakpoint(bp--md--major) {
    width: 45vw;
    padding: 5vw 7vw;

    form {
      width: 100%;
      //margin-top: 12vh;
    }
  }
  @include breakpoint(bp--lg--major) {
    width: 38vw;
  }

  [class="wfp--form-item"] {
    margin-bottom: $spacing-lg;
  }
}

.loginFormContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  @include breakpoint(bp--md--major) {
    justify-content: center;
  }
  margin-top: 1rem;
  padding-bottom: 6rem;
}

.button {
  display: flex;
}

.corporateButton {
  display: flex;
  margin-top: $spacing-sm;
}

.submitWrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.loginLogo {
  margin-bottom: 2rem;
  @include breakpointMax(bp--md--major) {
    display: none;
  }
  svg {
    width: 8vmin;
    height: auto;
  }
  path {
    fill: $brand-01;
  }
}

.loginContent {
  @include breakpointMax(bp--md--major) {
    display: none;
  }
  color: var(--ui-01);
  background: $brand-01;
  background-size: cover;

  position: fixed;
  right: 0;
  width: 62vw;
  @include breakpoint(bp--md--major) {
    width: 55vw;
  }
  @include breakpoint(bp--lg--major) {
    width: 62vw;
  }
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  h2 {
    width: 60%;
    font-size: 3rem;
    font-weight: 800;
    text-shadow: 0px 3px 12px rgba(#000, 0.3);
    display: none;
  }
  p {
    font-size: 1.2rem;
    margin-top: 1.4rem;
    padding-right: 5rem;
    line-height: 2rem;
    width: 60%;
  }
}

.userListItem {
  background: var(--ui-01);
}

.userListItemTitle {
  font-weight: 600;
}

.subTitle {
  font-size: 0.9rem;
}
