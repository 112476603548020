@import "scss/global";

.stock {
  &__summary {
    display: flex;
    align-items: center;
    background: $ui-02;
    padding: $spacing-lg $spacing-md;
    border-bottom: 1px solid $ui-04;
    &__icon {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--ui-01);
      border-radius: 100px;
      margin-right: 1em;
      .svg-inline--fa {
        width: 1.5rem;
        height: auto;
      }
    }
    &__name {
      font-size: 1.5rem;
      font-weight: 600;
      span {
        display: block;
        font-size: 0.9rem;
        font-weight: normal;
        margin-top: 0.3em;
        opacity: 0.7;
      }
    }
  }
}
