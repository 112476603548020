@import '~@wfp/ui/src/globals/scss/colors';
@import "~@wfp/ui/src/globals/scss/vars";
@import "~@wfp/ui/src/globals/scss/layout";
@import "~@wfp/ui/src/globals/scss/typography";

$color__green: rgb(67, 194, 41);
$input_border: 1px solid #dfe3e6;
$support-02: $color__green;
$support-04: $color__dark-green;


$desktop-header-height: 55px;
$mobile-detail-header-height: 60px;
$mobile-header-height: 70px;
$mobile-bottom-height: 60px;
$mobile-bottom-swipe-height: 75px;
