@import "scss/global";

.sidebar-item {
  cursor: pointer;
  padding: rem(20px);
  padding-bottom: rem(18px);
  @include breakpointMax(bp--md--major) {
    padding: rem(12px);
  }
  position: relative;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  border-bottom: 1px solid $ui-04;
  text-decoration: none;
  background: none;
  color: inherit;
  padding-right: rem(15px);
  font: inherit;
  outline: inherit;
  width: 100%;
  overflow: hidden;
  text-align: left;
  h5 {
    font-size: 0.95rem;
  }
  &__sub-title {
    font-size: 0.8rem;
  }
  &__thr-title {
    font-size: 0.8rem;
  }
  &__last-editor {
    color: $ui-05;
    font-size: 0.8rem;
  }
  &__comment {
    color: $ui-05;
    font-size: 0.8rem;
    //@include typescale("zeta");
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__content {
    flex-grow: 1;
  }
  &__type {
    /*background: lighten($interactive-01, 50);
    padding: 0.3em;
    border-radius: 1em;*/
    color: $interactive-01;
    display: inline-block;
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    .sidebar-item--incident & {
      color: $support-01;
    }
  }
  &__date {
    color: $ui-05;
    font-size: 0.8rem;
    text-align: right;
    white-space: nowrap;
    margin-left: -2rem;
    @include breakpoint(bp--sm--major) {
      span:first-child {
        &:after {
          content: ", ";
        }
      }
    }
    @include breakpointMax(bp--sm--major) {
      line-height: 1.3;
      margin-bottom: 0.4rem;
      span {
        display: block;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: -1em;
    .sync-icon {
      margin-top: 0.35rem;
    }
  }

  &--highlight {
    //color: $ui-01;
    //box-shadow: 0 -1px 0 0 $color__brand-01-20;
    //border-bottom-color: $color__brand-01-20;
    //background: $color__brand-01-10;
    h5 {
      color: $interactive-01;
      display: flex;
      align-items: center;
      &:before {
        content: "";
        display: block;
        width: 0.6em;
        height: 0.6em;

        margin-right: 0.3em;
        background: $interactive-01;
        border-radius: 1rem;
      }
      .svg-inline--fa {
        margin-left: 0.5em;
        height: 0.8em;
        width: auto;
        transition: all 0.3s;
      }
    }
    &:hover {
      @include breakpoint(bp--md--major) {
        h5 {
          .svg-inline--fa {
            transform: translateX(0.2em);
          }
        }
      }
    }
  }

  &--selected {
    //color: $ui-01;
    box-shadow: 0 -1px 0 0 $color__brand-01-20;
    border-bottom-color: $color__brand-01-20;
    background: $color__brand-01-10;
    &:hover {
      @include breakpoint(bp--md--major) {
        background: $color__brand-01-10 !important;
      }
    }
  }

  &__status {
    position: absolute;
    top: rem(20px);
    right: rem(20px);
  }
  &__status--served {
    //color: $support-02;
    @include typescale("zeta");
    &:before {
      display: inline-block;
      background-color: $support-02;
      content: "";
      margin-bottom: 0.1em;
      width: 0.6em;
      height: 0.6em;
      margin-right: 0.3em;
      border-radius: 1em;
    }
  }
  .address__title {
    margin-top: -0.2rem;
    margin-bottom: $spacing-2xs;
    span {
      font-weight: 200;
      font-style: italic;
    }
  }

  .address__sub-title {
    margin-bottom: $spacing-2xs;
    @include typescale("zeta");
  }

  &:hover {
    @include breakpoint(bp--md--major) {
      background: rgba($ui_02, 0.5);
    }
  }
}

.sidebar-item__wrapper {
  width: 100%;
}

.sidebar-item--sync {
  border: none;
  padding: 0;
}
.attendance-table__date__dateEl {
  position: relative;
  height: 100%;
}

.table__date__time {
  font-weight: 600;
  display: block;
}

.DateInput_input {
  max-width: 12rem;
}

.attendance-table__date-wrapper {
  color: $ui_05;
  margin-top: $spacing-xs;
  @include typescale("zeta");
}

.attendance-table__date {
  margin-right: 0.5em;
}

.attendance-table__details {
  margin-top: $spacing-xs;
}
