@import "scss/global";

.stock-value {
  font-weight: normal;
  margin-top: 0.1em;
  opacity: 0.8;
  /*height: 40px;
  line-height: 40px;
  padding: 0 0.5em;
  background: $ui-04;
  border: 1px solid $ui-05;
  border-left: 0;
  white-space: nowrap;
  min-width: 100px;
  text-align: right;*/
}
