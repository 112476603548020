@import "scss/global";

.school-year-group,
.month-group {
  .school-year-dropdown,
  .month-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $ui-04;
    cursor: pointer;

    .wfp--label {
      color: #0b77c2;
      font-weight: bold;
      margin: 1rem 0.5rem 1rem 1rem;
    }

    > svg {
      margin-left: 0.3rem;
      margin-right: 1rem;
      color: #0b77c2 !important;
    }
  }
}

.school-year-group {
  .wfp--label {
    font-size: 1rem;
  }
}

.month-group {
  .wfp--label {
    font-size: 0.9rem;
  }
}
