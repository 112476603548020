@import "scss/global";

.meals-edit__buttons {
  display: flex;
  justify-content: space-between;
  @include breakpointMax(bp--md--major) {
    flex-direction: column;
    align-items: flex-start;
    .wfp--btn {
      margin-bottom: $spacing-md;
    }
  }
}

.wfp--modal-header__heading {
  display: flex;
  justify-content: space-between;
  > .wfp--btn {
    margin-top: -0.5em;
  }
}

.meals-edit {
  &__question {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      margin-bottom: $spacing-md;
    }
    .wfp--btn {
      margin: 0.5em;
    }
  }

  .wfp--radio-button-group__vertical .wfp--radio-button-group-inside {
    flex-direction: column;
  }

  .wfp--radio-button-group .wfp--radio-button-group-inside {
    margin-top: 0;
  }

  .wfp--form-item {
    margin-bottom: $spacing-md;
  }

  .commodity-select {
    padding-bottom: 0;
    .wfp--form-requirement {
      max-height: none;
    }
  }
}

.no-meal-reasons-checkbox {
  margin-bottom: 0 !important;
  max-height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .wfp--form-item.wfp--checkbox-wrapper {
    margin-top: 0.325rem;
    margin-bottom: 0.325rem;
    max-width: fit-content;
    margin-right: 3rem;
  }
  .tooltiptext {
    max-width: fit-content;
    visibility: hidden;
    background-color: #0b77c2;
    color: #fff;
    text-align: center;
    padding: 6px;
    border-radius: 6px;
    height: fit-content;
    z-index: 1;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #0b77c2 transparent transparent transparent;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.no-meal-reasons-checkbox:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.no-meal-reasons-container {
  .wfp--radio-button-group {
    .wfp--label {
      margin-bottom: 0rem;
    }
  }
}

.commoditySource {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.disabled {
  display: none;
}

.container {
  display: block;
}

.replicate-section-buttons > *,
.repeat-section > *:not(:last-child) {
  margin-right: 1rem;
}

.repeat-section {
  display: flex;
  justify-content: left;
  align-items: center;

  .repeat-label {
    max-width: 6rem;
    margin-bottom: 0 !important;
    text-align: left;
  }

  .wfp--form-item {
    flex: none;

    .wfp--label {
      font-size: 0.8rem;
    }
  }
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 620px) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.replicate-meal-section {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
}
