.wfp--input-wrapper .commodity-text-view {
  width: fit-content;
}

.commodity-select__input {
  .wfp--react-select--view-container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    width: 400px;
    height: 40px;
    background-color: white;
    border: 1px solid #8ca4b5;
  }
  .wfp--react-select-view-container{
    width: 400px;
    min-width: 240px;
    order: initial;
    position: relative;
    box-sizing: border-box;
  }
  .wfp--react-select--view-value-container{
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 2px 8px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    border: 1px solid #8ca4b5;
  }
}