.sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  &--small {
    font-size: 1rem;
  }

  &--additional {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    margin-bottom: -5px;
    button {
      margin-bottom: 5px;
    }

    .edit-nav-link,
    .edit-nav-link > button {
      margin-left: 0.5rem;
    }

    .deleted-label {
      font-family: "Open Sans", sans-serif;
      font-size: 0.875rem;
      font-weight: 700;
      color: #c5192d;
    }
  }
}
