@import 'scss/global';

.delivery-edit__general {
  display: flex;
  justify-content: flex-start;
  > div {
    flex: 0;
    margin-right: 1rem;
  }
  @include breakpointMax(bp--md--major) {
    flex-direction: column;
  }
}

.delivery-edit__commodities {
  @include breakpointMax(bp--md--major) {
    .repeating-entry__content {
      grid-template-columns: repeat(2, 50% [col-start]);
      grid-column-gap: 10px;
      grid-row-gap: 15px;
    }
    .wfp--form-item {
      input {
        width: 100%;
      }
    }
    .wfp--form-item:nth-child(5) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.SingleDatePickerInput {
  display: flex;
  flex-direction: row-reverse;
}

.batchnumber {
  width: 30%;
  padding-bottom: 1rem;
}

.wfp--form-required {
  flex-direction: row;
  color: #c5192d;
  display: contents;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  max-height: 12.5px;
  line-height: 1.5px;
}

.qr-code {
  position: fixed;
  z-index: 10000;
  box-shadow: 0px 10px 30px -5px rgba(#000, 0.5);
  div {
    //border: 1px dashed #fff !important;
    box-shadow: none !important;
    //box-shadow: rgba($interactive-01, 0.9) 0px 0px 0px 5px inset !important;
    &:after {
      content: '';
      border: 2px dashed #fff !important;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
  @include breakpointMax(bp--md--major) {
    top: 0;
    padding-top: 50vw;
    background: $text-01;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    .wfp--btn {
      margin: 2rem;
      padding: 1rem;
      height: 80px;
      font-size: 1.3rem;
      //transform: translate8scale(1.3);
    }
  }
  @include breakpoint(bp--md--major) {
    top: 50%;
    left: 50%;
    width: 500px;
    height: 500px;
    margin-left: -250px;
    margin-top: -250px;
    background: var(--ui-01);
    .wfp--btn {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 100;
    }
  }
}
.waybill {
  margin-bottom: 1rem;
  @media (max-width: 440px) {
    margin-bottom: 2rem;
  }
  .checkboxWaybill {
    display: flex;
    margin-top: 10px;
    float: right;
    .checkbox {
      margin-left: 10px;
    }
  }
  .disabled-field {
    pointer-events: none;
    background-color: lightgrey;
  }
}

.hidden-id-field {
  display: none;
}

.deliveries-edit {
  .wfp--form-requirement {
    max-height: none;
  }

  .commodity-select__input {
    width: 100%;
  }

  .batch-number-select {
    .wfp--react-select__menu {
      max-height: 200px;
      overflow: hidden;
    }
  }

  .wfp--module--no-margin.wfp--module--light.wfp--module {
    .wfp--form-item {
      margin-bottom: $spacing-md;
    }
    .commodity-select {
      padding-bottom: 0;
    }
    .wfp--radio-button-group {
      padding-bottom: 0 !important;
      .wfp--radio-button-group-inside {
        margin-top: 0;
        .wfp--form-item {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  @media (max-width: 570px) {
    .wfp--module__content {
      padding-top: 60px !important;
    }
  }
}
