@import "scss/global";

.stock-history {
  &__overview {
    @include breakpoint(bp--md--major) {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    @include breakpointMax(bp--md--major) {
      width: 100%;
    }
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
    .wfp--module {
      @include breakpointMax(bp--md--major) {
        margin-bottom: $spacing-md;
      }
    }
  }

  &__chart {
    @include breakpoint(bp--md--major) {
      margin-left: $spacing-md;
      flex: 1;
    }
  }

  &__current-stock {
    min-width: 20%;
    .wfp--module__content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
    }
    font-size: 2rem;
    .wfp--value__title {
      font-weight: 400;
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
    .wfp--value__secondary-value {
      margin-top: 0.5rem;
      font-size: 1rem;
      opacity: 0.6;
    }
  }
  /*.sidebar-item__title {
    font-weight: 400;
    font-size: 1rem;
  }*/
  // .sync-icon,
  // .commodities-show-small {
  //   display: none;
  // }
  &__value {
    font-size: 1.5rem;
    &--delivery {
      color: $support-02;
    }
    &--incident {
      color: $support-01;
    }
  }
  &__module {
    margin-bottom: 1rem;
  }
}

.stock-history__module a {
  color: $text-01;
  text-decoration: none !important;
}
