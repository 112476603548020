@import "scss/global";

.headerData {
  text-align: left;
  vertical-align: bottom;
}

.modality {
  vertical-align: middle;
}

.boldText {
  font-weight: 600;
}

.borderTop {
  td {
    border-top:  1pt solid #000000;
  }
}

.underlineText {
  text-decoration: underline;
}

.numCell {
  text-align: right;
}

.reportTHead {
  font-weight: normal !important;
}

.sectionTitle {
  margin-bottom: 0.8rem;
}

.table {
  td {
    padding: 0.75rem;
  }
}

.logEntryHeader {
  display: flex;
  align-items: center;
}

.logEntryComments {
  overflow-wrap: anywhere;
}

.verticalSeparator {
  background-color: #dfe3e6;
  height: 1rem;
  width: 1px;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (max-width: 620px) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.schoolInfo {
  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 5vw;
  grid-row-gap: 1rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & li {
    & p {
      &:first-child {
        font-weight: 600;
        font-size: 0.875rem;
      }
    }
  }
}

.reportModule {
  overflow-x: auto;

  @media (max-width: 767px) {
    padding: 0.75rem !important;
  }
}

.signatureImg {
  width: 100%;
}
