@import "scss/global";

.sync-select {
  .repeating-entry__content {
    > div {
      width: 100%;
    }
  }
  .sidebar-item {
    padding: 0;
    border: 0;
  }
  .sync-icon {
    display: none;
  }
}
