@import "scss/global";

.register {
  display: flex;
  overflow: auto;
}

.password {
  margin-left: $spacing-xs;
}

.nameWrapper,
.contactWrapper,
.schoolWrapper,
.locationWrapper {
  @include breakpoint(bp--lg--major) {
    display: flex;
    > * {
      margin-right: $spacing-md;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  [class="wfp--input-wrapper"] {
    width: 100%;
  }
}

.schoolWrapper,
.locationWrapper {
  :global {
    .wfp--form-item {
      justify-content: space-between;
    }

    .wfp--form__helper-text {
      max-height: none;
    }
  }
}

.registerForm {
  display: flex;
  height: 100vh;
  overflow: auto;

  flex-direction: column;
  @include breakpointMax(bp--lg--major) {
    width: 100%;
    padding: 5vw;
  }

  @include breakpoint(bp--lg--major) {
    width: 60vw;
    padding: 2vw 7vw 7vw 7vw;

    form {
      width: 100%;
      flex-grow: 1;
      margin-top: 8vh;
    }
  }
  [class="page-title"] {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: $spacing-xl;
    @include breakpointMax(bp--md--major) {
      margin-bottom: $spacing-md;
    }
  }
  :global {
    .wfp--form-item {
      margin-bottom: $spacing-lg;
    }
    .wfp--form-long {
      display: block !important;
    }
  }
}

.button {
  display: flex;
}

.corporateButton {
  display: flex;
  margin-top: $spacing-sm;
}

.submitWrapper {
  display: flex;
  align-items: center;
}
.backLink {
  margin-bottom: 2rem;
}

.loginLogo {
  margin-bottom: 2rem;

  @include breakpointMax(bp--md--major) {
    display: none;
  }
  svg {
    width: 8vmin;
    height: auto;
  }
  path {
    fill: $brand-01;
  }
}

.registerContent {
  @include breakpointMax(bp--md--major) {
    display: none;
  }
  color: var(--ui-01);
  background: $brand-01;
  background-size: cover;

  width: 62vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  h2 {
    width: 60%;
    font-size: 3rem;
    font-weight: 800;
    text-shadow: 0px 3px 12px rgba(#000, 0.3);
    display: none;
  }
  p {
    font-size: 1.2rem;
    margin-top: 1.4rem;
    padding-right: 5rem;
    line-height: 2rem;
    width: 60%;
  }
}

ol {
  li {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    padding: 0.5%;
  }
}

.termsContent {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  flex-direction: column;
  @include breakpointMax(bp--lg--major) {
    width: 100%;
    padding: 6vw 6vw 6vw 6vw;
  }

  @include breakpoint(bp--lg--major) {
    width: 80vw;
    padding: 7vw 10vw 10vw 10vw;
  }
  [class="page-title"] {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: $spacing-xl;
    @include breakpointMax(bp--md--major) {
      margin-bottom: $spacing-md;
    }
  }
}

.submitMessageWrapper {
  margin-top: 0.5rem;

  label {
    color: #085992;
  }
}

.submitErrorMessage {
  color: #c5192d;
  font-size: 12px;
  font-weight: 550;
  margin-bottom: 0.4rem;
}

.invalidRoleStyle {
  :global {
    .wfp--label {
      text-decoration: underline;
      text-decoration-color: #c5192d;
      text-decoration-thickness: 0.15rem;
    }
  }
}
