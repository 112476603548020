@import "scss/global";

.main-content {
  flex: 1;
  width: 100%;
  height: 100%;
  @include breakpoint(bp--md--major) {
    overflow: auto;
    padding: $spacing-lg;
  }
  &__content {
    @include breakpointMax(bp--md--major) {
      overflow: auto;
      padding: $spacing-md;
      padding-top: $mobile-detail-header-height;
      height: calc(100vh - #{$mobile-detail-header-height} * 1.5);
      .wfp--module {
        margin-left: -$spacing-md;
        margin-right: -$spacing-md;
      }
    }
    @include breakpoint(bp--md--major) {
      //min-height: 100%;
      max-height: 100%;
    }

    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    grid-template-columns: repeat(1, 1fr);
    //grid-auto-rows: minmax(10px, auto);
    .wfp--module__inner {
      height: 100%;
    }
  }

  background: $ui-02;
  > .empty {
    height: 100%;
  }
}

// We need special css for the report page
.report-content {
  @media (max-width: 620px) {
    .main-content__content {
      padding-top: calc(#{$mobile-detail-header-height} * 1.5) !important;
    }
    .mobile-nav-details {
      height: calc(#{$mobile-detail-header-height} * 1.5) !important;
      padding: 0.2rem 0 0;
    }
  }
}
