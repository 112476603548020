@import "scss/global";

.chart-tooltip {
  text-align: center;
  h3 {
    color: $text-02;
    font-weight: normal;
    font-size: 0.7rem;
    margin-top: -0.2rem;
  }
  h4 {
    margin-bottom: $spacing-xs;
    @include typescale(epsilon);
  }
}
