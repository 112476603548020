@import "scss/global";

.waybill-select {
  .commodity-select {
    &__input {
      display: flex;
      .wfp--react-select-container {
        width: 400px;
        min-width: 240px;
        max-width: 400px;
        order: initial;
      }
      .wfp--react-select__single-value {
        font-weight: 600;
        font-size: 0.9rem;
        margin-top: -0.02em;
      }
    }
    &__stock {
      font-size: 0.7em;
    }
    &__row {
      display: flex;
      flex-direction: column;
    }
  }
}

.incidents-edit {
  .wfp--form-requirement {
    max-height: none;
  }

  .wfp--radio-button-group {
    padding-bottom: 0 !important;
    .wfp--radio-button-group-inside {
      margin-top: 0;
      .radioButtonWrapper {
        margin-bottom: 0;
      }
    }
  }

  .cause-radio-button {
    padding-bottom: 0.5rem !important;
  }

  .commodity-select__input {
    width: 100%;
  }

  .wfp--module--no-margin.wfp--module--light.wfp--module {
    .wfp--form-item {
      margin-bottom: $spacing-md;
    }
    .commodity-select {
      padding-bottom: 0;
    }
    .wfp--radio-button-group {
      padding-bottom: 0 !important;
    }
  }

  @media (max-width: 570px) {
    .wfp--module__content {
      padding-top: 60px !important;
    }
  }
}
