@import "scss/global";

.sidebar-header {
  display: flex;
  justify-content: space-between;
  padding: $spacing-md;
  background: $ui-02;
  border-bottom: 1px solid darken($ui-04, 3);

  .buttons-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    margin-bottom: -5px;
    button {
      margin-bottom: 5px;
    }
  }
}
