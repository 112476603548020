.filtering {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.8rem;

  .wfp--form-item {
    flex-direction: row;
    align-items: center;

    margin-inline-start: 0rem;
    margin-inline-end: 1rem;

    margin-bottom: 0.4rem;

    > .wfp--label {
      margin: 0;
      margin-inline-end: 0.5rem;
    }
  }

  .wfp--select {
    flex-direction: row;
    align-items: center;

    .wfp--select-input {
      text-overflow: ellipsis;
    }
  }

  .wfp--input-wrapper {
    width: 100%;
    max-width: 200px;
  }

  .wfp--form-item {
    max-width: 300px;
  }

  .wfp--btn {
    margin-inline-end: 1rem;
  }

  .wfp--btn.wfp--btn--secondary {
    margin-left: auto;
  }

  .filter-buttons {
    margin-left: auto;
  }
}
