.school-year-show {
  .wfp--list__element {
    margin-bottom: 0.75rem !important;
  }
  .wfp--list__element:last-child {
    margin-bottom: 0rem !important;
  }
  .wfp--list__element__title {
    margin-bottom: 0.25rem !important;
  }
  .sub-title {
    margin-top: 1.25rem !important;
    margin-bottom: 0.75rem !important;
  }
  .disabled-button-school-year-show {
    position: relative;
  }
  .disabled-button-school-year-show .tooltiptext {
    max-width: fit-content;
    visibility: hidden;
    background-color: #0b77c2;
    color: #fff;
    text-align: center;
    padding: 6px;
    border-radius: 6px;
    height: fit-content;
    z-index: 1;
    position: absolute;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;

    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }
  .disabled-button-school-year-show .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  .disabled-button-school-year-show:hover .tooltiptext {
    visibility: visible;
    opacity: 2;
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;

    overflow: visible;
    white-space: normal;
    width: auto;
    position: absolute;
  }
}

.uneditable-year-warning {
  font-weight: "bold";
}
