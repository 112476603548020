@import "scss/global";

.vendorContainer {
  padding: 20px;
  background-color: #f0f3f6;
  margin-bottom: 2%;
  border-radius: 6px;
  padding-bottom: 2%;
}
.container {
  display: flex;
  flex-direction: row;
  margin: 5px;
  .wfp--react-select-container {
    order: 0 !important;
    width: 240px;
    order: initial;
  }
  .wfp--radio-button-group {
    padding-bottom: 20px !important;
  }
  .units {
    width: 140px !important;
  }
  .vendor {
    width: 250px !important;
  }
}
.reference {
  margin-top: auto !important;
}
.styles_addButtonWrapper__kvRDa {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.container-date-time {
  display: flex;
  flex-grow: 0.2 !important;
  flex-shrink: 0.2 !important;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  flex-wrap: wrap;

  @media (min-width: 420px) {
    .camera-component-button {
      margin-left: auto;
    }
  }
  @media (max-width: 420px) {
    .camera-component-button {
      margin-top: 0.5rem;
    }
  }
}

.purchasedetail {
  margin-bottom: 0.5rem;

  .purchase-date {
    margin-right: 10px;
  }
}

.wfp--number {
  float: right !important;
}

.disabled {
  display: none;
}

.checkboxVendor {
  display: flex;
  margin-top: 10px;
  .checkbox {
    margin-left: 10px;
  }
}

.purchase {
  margin-top: 10px;
}

.cash-to-school {
  .container {
    flex-wrap: wrap;
  }

  .commodities-section {
    .camera-component-button {
      margin-bottom: $spacing-md;
    }
    .commodity-select {
      padding-bottom: 0;
    }
    .other-costs > div {
      width: 100%;
    }
    .container {
      flex-wrap: wrap;
      margin-bottom: 1rem;

      .wfp--form-item {
        margin-bottom: 1rem;
        margin-right: 0;
        width: 50% !important;

        @media (max-width: 570px) {
          width: 100% !important;
        }

        .wfp--input-wrapper {
          input {
            width: 240px;
          }
        }
      }
    }
    .grand-total {
      margin-top: 1rem;
    }
  }
}
