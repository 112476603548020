@import "scss/global";

.modal-extended.wfp--modal {
  @include breakpointMax(bp--md--major) {
    align-items: stretch;
    background: var(--ui-01);
    .wfp--modal-container {
      min-width: 100%;
      min-height: 100%;
      border-radius: 0;
      border-top: none;
      height: inherit;
    }

    .wfp--modal-inner {
      align-items: stretch;
      min-height: 100%;
    }

    .wfp--modal-content {
      margin-bottom: 1rem;
    }

    .wfp--modal-footer {
      margin-left: -3%;
      margin-right: -3%;
      padding: 6% 3%;
      border-radius: 0 0 5px 5px;
    }
    .wfp--modal-close {
      transform: scale(1.7);
    }
  }

  @include breakpoint(bp--sm--major) {
    .wfp--modal-footer {
      margin-left: -3rem;
      margin-right: -3rem;
      padding: 2% 3rem;
      border-radius: 0 0 5px 5px;
    }
  }

  @include breakpoint(bp--md--major) {
    .wfp--modal-container {
      .wfp--modal--wide & {
        min-width: 70%;
      }
      max-width: 900px;
      border-radius: 10px !important;
      border-top: none;
    }
    .wfp--modal-footer {
      margin-left: -3rem;
      margin-right: -3rem;
      padding: 3% 3rem;
      border-radius: 0 0 10px 10px;
    }
  }

  @include breakpoint(bp--lg--major) {
    .wfp--modal-container {
      .wfp--modal--wide & {
        min-width: 70%;
      }
      max-width: 900px;
      border-radius: 5px;
    }
  }

  .wfp--modal-header {
    display: block;
  }
}

.hidden-btn {
  display: none;
}

.meals-edit__buttons {
  display: flex;
  justify-content: space-between;
}
