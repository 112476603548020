.commodity-select {
  &__input {
    display: flex;
    .wfp--react-select-container {
      width: 400px;
      min-width: 240px;
      order: initial;
    }
    .react-select-container {
      width: 100%;
      min-width: 240px;
      order: initial;
    }
    .wfp--react-select__single-value {
      font-weight: 600;
      font-size: 0.9rem;
      margin-top: -0.02em;
    }
  }
  &__stock {
    font-size: 0.7em;
  }
  padding-bottom: 1rem;
}
