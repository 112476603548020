@import "scss/global";

.mobileNavDetails {
  @include breakpoint(bp--md--major) {
    //display: none;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
  }
  @include breakpointMax(bp--md--major) {
    background: white;
    //color: $ui-01;
    //backdrop-filter: blur(20px);
    border-bottom: 1px solid $ui-04;
    height: $mobile-detail-header-height;
    align-items: center;
    display: flex;
    position: fixed;
    z-index: 100;
    top: 0px;
    width: 100%;
  }
  > a {
    display: block;
    text-decoration: none;
    padding: $spacing-md $spacing-md;
    @include breakpoint(bp--md--major) {
      display: none;
    }
  }
  .svg-inline--fa {
    height: 1.3rem;
    width: auto;
    margin-right: 0.5rem;
  }
  > div {
    margin-right: $spacing-xs;
  }
  h1 {
    font-size: 1rem;
    flex: 1;
    @include breakpoint(bp--md--major) {
      font-size: 2rem;
      font-weight: 200;
    }
    span {
      display: block;
    }
  }
}

.subTitle {
  font-size: 0.9rem;
  font-weight: normal;
  color: $text-02;
}

.right {
  height: fit-content;
}
