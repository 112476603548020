@import "scss/global";

.sync-icon {
  display: inline-block;
  min-width: 1.3rem;
  height: 1.3rem;
  border-radius: 1rem;
  background: $support-light-03;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  padding: 0.5em 0.5em;
  white-space: nowrap;
  color: darken($support-03, 15);
  font-weight: 400;
  @include breakpointMax(bp--md--major) {
    font-size: 0.6em;
  }
  .svg-inline--fa {
    margin-left: 0.5em;
    path {
      fill: darken($support-03, 15);
    }
    width: 0.7rem;
    height: 0.7rem;
  }
  &--synced {
    //background: $support-light-02;
    background: none;
    color: $support-02;
    .svg-inline--fa {
      path {
        fill: $support-02;
      }
    }
  }
}

.sync-delete {
  display: inline-block;
  min-width: 1.3rem;
  height: 1.3rem;
  border-radius: 1rem;
  margin-top: 5px;
  background: lighten($support-01, 15);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  padding: 0.5em 0.5em;
  white-space: nowrap;
  color: darken($support-01, 15);
  font-weight: 400;
  @include breakpointMax(bp--md--major) {
    font-size: 0.6em;
  }
  .svg-inline--fa {
    margin-left: 0.5em;
    path {
      fill: darken($support-01, 15);
    }
    width: 0.7rem;
    height: 0.7rem;
  }
  &--synced {
    //background: $support-light-02;
    background: none;
    color: $support-01;
    .svg-inline--fa {
      path {
        fill: $support-01;
      }
    }
  }
}
