@import "scss/global";

.meals-edit__buttons {
  display: flex;
  justify-content: space-between;
}

.attendance-edit {
  @include breakpointMax(bp--md--major) {
    .wfp--table__show-value {
      padding: 0;
    }
    .wfp--table__title {
      font-size: 0.9em;
    }
    .wfp--table__row-total {
      display: none;
    }
    .wfp--number {
      margin: -1px;
      input {
        min-width: 1.6rem;
        padding: 0 0.5rem;
        min-height: 2.5rem;
        position: relative;
        //border-bottom-width: 3px;
        &:focus {
          z-index: 1000;
        }
      }
    }
  }
}

.tableMobile {
  :global {
    th {
      font-size: 0.8em;
    }
  }
}
