@import "scss/global";

.chart-label {
  font-size: 13px;
  fill: $ui-05;
  @include font-family;

  &__not-taken {
    fill: #f69284;
    font-weight: 600;
  }
}

.chart-tick--large {
  stroke-width: 1px;
  stroke: rgb(102, 102, 102);
}

.chart-tooltip {
  color: #26323d;
  box-shadow: 0 4px 20px 4px rgba(0, 20, 60, 0.1),
    0 4px 80px -8px rgba(0, 20, 60, 0.2);
  background-color: white;
  padding: 1em;
  max-width: 200px;
}
